/* .navbar {
   position: absolute;
   width: 450px;
   height: auto;
   z-index: 6;
} */

.menuIcon {
   inset: 25px 0 0 25px;
   font-size: 50px;
   position: absolute;
   z-index: 6;
   cursor: pointer;
}
.menuIcon:hover {
   color: var(--primary-color)
}
.navbar-menu {
   position: absolute;
   top: 0; left: -1000px;
   font-family: 'Lemon', sans-serif;
   width: 450px;
   height: 100dvh;
   background: var(--bg-color);
   z-index: 6;
   display: flex;
   flex-direction: column;
   align-items: center;

   transition: all 0.62s ease-in;
}
.navbar-menu::before {
   content: '';
   position: absolute;
   z-index: -1;
   width: 450px;
   height: 100dvh;
   background: radial-gradient(185.41% 85.93% at 34.59% 44.27%, rgba(27, 155, 160, 0.15) 0.13%, rgba(17, 15, 17, 0.24) 56.09%);
}
.navbar--top {
   display: flex;
   justify-content: space-between;
   width: 90%;
   padding: 30px 0;
   font-size: 34px;
}
.navbar--top span {
   font-size: 44px;
   cursor: pointer;
}
.navbar--links {
   font-size: 32px;
   text-align: center;
   width: 100%;
}
.navbar--link:first-child  {
   border-top: 1px solid var(--primary-color);
}
.navbar--link.active-section {
   background-color: rgba(33, 230, 237, 0.4);
}
.navbar--link {
   border-bottom: 1px solid var(--primary-color);
   padding: 30px 0;
}
.navbar--link:hover {
   background-color: rgba(33, 230, 237, 0.20);
   cursor: pointer;
}

.navbar--visible {
   transform: translateX(1000px);
   transition: all 0.62s ease-out;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
   .navbar-menu {
      width: 100dvw;
   }
}
