.projects {
   position: absolute;
   width: 100dvw;
   height: 100dvh;
   background-size: cover !important;
   z-index: 2;
   background-color: var(--bg-color);


   display: flex;
   flex-direction: column;
   justify-content: center;

   opacity: 1;
   visibility: visible;
   transition: all 0.9s ease-in 1s;
}
.projects--invisible {
   opacity: 0;
   visibility: hidden;
   transition: all 0.8s ease-out;
   background: var(--bg-color);
}
.projects .title::before {
   content: 'Projects';
}
.title--container {
   height: 100px;
   width: 80%;
}
.projects-container--grid {
   margin: 10px 0 25px 130px;
   overflow-y: scroll;
   width: 100%;
   display: grid;
   grid-template-rows: repeat(2, 320px);
   grid-template-columns: repeat(3, 435px);
   gap: 10px 15px;
   position: relative;
   
   transition: all 0.6s ease-in-out;
}
.projects-container--grid.home-content-shifted {
   margin-left: 500px;
}
.project-container {
   border-radius: 40px;
   border: 2px solid rgba(33, 230, 237, 0.3);
   background: linear-gradient(123deg, #070508 15.69%, #062526 48.54%, #070508 92.06%);
   position: relative;
}
.project--card {
   padding: 15px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
}
.project--card .project--image{
   position: relative;
   width: 100%;
   height: 160px;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   border-top-left-radius: 30px;
   border-top-right-radius: 30px;
}
.project--links {
   position: relative;
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 10px;

   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease-out;
}
.project--links::before {
   content: '';
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0; left: 0;
   backdrop-filter: blur(3px);
   background-color: rgba(6,37,38, 0.25);
   z-index: -1;
   transition: all 0.2s linear;
}

.hovered--project.project--image .project--links {
   opacity: 1;
   visibility: visible;
   transition: all 0.3s ease-in;
   z-index: 7;
}

.project--card h3 {
   font-family: 'Ubuntu Mono', serif;
   margin-bottom: 3px;
   font-size: 22px;
   margin: 5px 15px;
}
.project-title--container {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.project-title--container .technologies-span {
   font-family: 'Ubuntu Mono', serif;
   font-size: 14px;
   color: rgba(33, 230, 237, 0.80);
   margin: 0 3px;
   opacity: 1;
}
.project-title--container .technologies {
   text-align: end;
   padding: 5px 15px 0 0;
   text-wrap: nowrap;
}
.technologies svg {
   margin: 0 3px -3.5px 3px;
   fill: rgba(33, 230, 237, 0.6);
   font-weight: 700;
}
.project--card .ubuntu {
   margin-left: 0;
}
.project--card p {
   font-family: 'Space Mono', 'Syne Mono', monospace;
   line-height: 16px;
   text-indent: 15px;
}
.project--card p > span {
   opacity: 0.6;
}
.see-more--container {
   display: flex;
   width: 100%;
   justify-content: center;
}

.btn {
   position: relative;
   padding: 0.8em 1em;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   border: 1px solid rgba(33, 230, 237, 0.40);
   background: linear-gradient(128deg, #0B3030 -26.98%, #070508 48.27%, #051818 124.42%);
   border-radius: 10px;
   color: var(--text-color);
   font-weight: 300;
   font-size: 18px;
   font-family: 'Ubuntu Mono', sans-serif;
   overflow: hidden;
   transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}
.btn:hover{
   transform: scale(1.02);
   transition: all 0.5s ease-in;
}
.btn::before,
.btn::after {
   content: '';
   position: absolute;
   right: 0;
   bottom: 0;
   width: 100px;
   height: 100px;
   border-radius: 50%;
   background: var(--primary-color);
   opacity: 0;
   transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
   z-index: 7;
   transform: translate(100%, -25%) translate3d(0, 0, 0);
}
.btn:hover::before,
.btn:hover::after {
   opacity: 0.15;
   transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}
 
.btn:hover::before {
   transform: translate3d(50%, 0, 0) scale(0.9);
}
 
.btn:hover::after {
   transform: translate(50%, 0) scale(1.1);
}
.btn:active {
   transform: scale(0.95);
   transition: all 0.3s ease-in-out;
}

.projects-container--grid {
   .btn-loader {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 50%; left: 50%;
      transform: translate(-60%, -50%);
      border-radius: 10px;
      z-index: 8;
      background: none;
      visibility: visible;
      transition: all 0.3s ease-in;
   }
   
   .spinner {
      position: relative;
      width: 75px; height: 75px;
      background: var(--gradient);
      border-radius: 50%;
      z-index: 10;
      animation: rotateCircle 1.5s linear infinite;
   }
   .spinner::before {
      content: '';
      position: absolute;
      left: 50%; top: 50%;
      transform: translate(-50%, -50%);
      width: 65px; height: 65px;
      z-index: 9;
      background: var(--bg-color);
      border-radius: 50%;
   }

}


@media only screen and (min-width: 768px) and (max-width: 1367px){
   .projects-container--grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px 0 25px 75px;
      overflow-x: hidden;
      height: 500px;
      max-width: 90%;
   }
   .projects-container--grid.home-content-shifted {
      margin-left: 75px;
      width: 150%;
   }
   .project-container{
      max-width: 435px;
      transition: all 0.5s linear;
   }
   .projects-container--grid.home-content-shifted .project-container:nth-child(odd) {
      margin-left: 450px;
      transition: all 0.5s linear;
   }

   .btn span::before {
      content: '';
      position: absolute;
      width: 150px;
      left: -20px;
      top: -20px;
      height: 90px;
      background: var(--bg-color);
      z-index: -1;
      border-radius: 15px;
   }
}

@media (orientation: portrait) {
   .projects-container--grid {
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      width: 80%;
      height: 80%;
      display: flex;
      justify-content: center;
   }
   .project-container {
      max-width: 75%;
      height: 325px;
   }
   .projects-container--grid.home-content-shifted .project-container {
      margin-left: 450px;
      transition: all 0.5s linear;
   }
   .btn {
      margin-top: 15px;
   }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
   .projects > * {
      overflow: unset;
   }
   .projects {
      overflow: unset;
      height: 100dvh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
   }
   .title--container {
      position: absolute;
      top: 0; left: 0;
   }
   .title {
      max-width: 200px;
   }
   .projects-container--grid {
      display: flex !important;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 400px;
      /* height: 500px;
      max-height: 600px; */
      overflow-y: scroll !important;
      margin: 35px 0 15px 0;
   }
   .see-more--container {
      align-items: flex-start;
      margin-bottom: 20px;
   }
   .btn{ 
      margin: 0;
   }
   .project-container {
      width: 100%;
      max-width: unset;
      height: unset;
      max-height: unset;
      min-height: 375px;
   }
   .project--card {
      width: 100%;
      height: unset;
      max-height: unset;
   }
}