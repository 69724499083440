.certifications--container {
   width: 100%;
   height: 30dvh;
   display: flex;
   justify-content: center;
}
.certifications--list {
   display: flex;
   flex-wrap: wrap;
   width: 90%;
   justify-content: space-between;
   gap: 5% 0;
}
.card {
   position: relative;
   width: 24%;
   height: 90%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   overflow: hidden;
   transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);

   border-radius: 40px;
   border: 1px solid rgba(33, 230, 237, 0.40);
   background: radial-gradient(185.41% 85.93% at 34.59% 44.27%, #05161a 0%, #081218 47.5%);
}
.card--image {
   width: 90%;
   height: 80%;
   padding: 10px 0;
}
.card--title {
   font-family: 'Ubuntu Mono', monospace;
   font-size: 20px;
   margin: 2px;
   text-align: center;
}
.card--image-img {
   width: 100%;
   height: 80%;
   background-size: contain;
   background-position: center;
   background-repeat: no-repeat;
   margin-bottom: 10px;
}

.card--content {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(-45deg);
   width: 100%;
   height: 100%;
   padding: 20px;
   display: grid;
   box-sizing: border-box;
   border: 1px solid rgba(33, 230, 237, 0.40);
   background: linear-gradient(to left bottom, #070508, #0a090f, #0a0e14, #081218,  #081218, #0a0e14, #0a090f, #070508);
   opacity: 0;
   transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}
.card:hover .card--content {
   transform: translate(-50%, -50%) rotate(0);
   border: none;
   opacity: 1;
}
.card--date {
   padding: 5px 0;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: var(--primary-color);
   font-size: 16px;
}
.card--description {
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: rgba(249,250,250, 0.8);
   font-size: 12px;
}
.card--description .button {
   cursor: pointer;
   line-height: 12px;
   font-size: 13px;
   margin-left: 5px;
}
.card--description .button:hover {
   color: var(--primary-color)
}
.card--content .btn {
   justify-self: center;
}
.card--content .btn svg {
   margin-left: 5px;
}

@media screen and (max-width: 640px) {
   .certifications--list {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: center;
      gap: 2.5%;
      height: 200dvh;
   }
   .certifications--container {
      height: auto;
   }
   .card {
      width: 90%;
   }
}

@media screen and (min-width: 640px) and (max-width: 1140px){
   .certifications--list {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      gap: 2.5% 2.5%;
   }
   .certifications--container {
      height: 80dvh;
   }
   .card {
      width: 100%;
   }
}

@media screen and (min-width: 640px) and (max-height: 460px) {
   .certifications--container {
      height: 180dvh;
   }
}