.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(to right bottom, #070508, #0a090f, #0a0e14, #081218,  #081218, #0a0e14, #0a090f, #070508);
}
.error-page__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--text-color);
    font-family: 'Space Mono', 'Syne Mono', monospace;
}
.error-page__container h1.error-page__container__title {
    font-size: 18rem;
    position: absolute;
    top: 45%; left: 50%;
    transform: translate(-50%, -55%);
    padding: 0 20px;
    font-weight: 900;
    margin: 0 0 0 -12px;
    color: #081218;
    text-transform: uppercase;
    text-shadow: -1px -1px 0 var(--primary-color), 1px 1px 0 var(--text-color);
    letter-spacing: -15px;
}
.error-page__container__divider {
    z-index: +1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 18rem;
}
.error-page__container__divider h2.error-page__container__subtitle {
    font-size: 5rem;
    font-weight: 700;
    margin: 0;
    width: 90%;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    letter-spacing: 10px;
    padding: 5px;
    text-shadow: 2px 2px 0 var(--primary-color);
}