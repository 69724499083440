.about {
   width: 100dvw;
   height: 100dvh;
   background-size: cover !important;
   position: absolute;

   opacity: 1;
   visibility: visible;
   transition: all 0.9s ease-in 1s;
   z-index: 3;
   display: flex;
   align-items: center;
   background: var(--bg-color);
   display: flex;
}

.about--invisible {
   opacity: 0;
   visibility: hidden;
   transition: all 0.9s ease-out;
   background: var(--bg-color);
}

.title {
   font-size: 48px;
   top: 15px; left: 120px;
   font-family: 'Lemon', serif;
   position: absolute;
}
.title::before{
   content: 'About Me';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 5px; left: 5px;
   z-index: -1;

   background: linear-gradient(0deg, #21E6ED -10.33%, rgba(27, 113, 169, 0.75) 47.55%, rgba(14, 29, 37, 0.10) 100%);
   background-clip: text;
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
}
.containers {
   position: absolute;
   width: 75%;
   top: 50%; left: 50%;
   transform: translate(-60%, -50%);
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   gap: 50px;
   transition: all 0.4s linear;
}
.containers.home-content-shifted {
   margin-left: 500px !important;
   transition: all 0.4s linear;
}
.img-container {
   flex: 1.5;
   height: 625px;
   min-width: 350px;
   position: relative;
   background: var(--bg-color);
   display: flex;
   flex-direction: column;
   align-items: center;
   border-radius: 40px;
   border: 1px solid rgba(33, 230, 237, 0.40);
   background: radial-gradient(185.41% 85.93% at 34.59% 44.27%, #051F20 0%, #070508 47.5%);
}

.short--descriptions {
   display: grid;
   grid-template-rows: repeat(4, 1fr);
   grid-template-columns: 100px 200px;
   gap: 10px 5px;
   margin-top: 25px;
 
   justify-items: start;
}
.short--descriptions--subject{
   font-family: 'Ubuntu Mono', serif;
   font-weight: 600;
   font-size: 20px;
   text-align: end;
}
.short--descriptions--text {
   font-family: 'Inter', sans-serif;
   font-size: 18px;
}
.text-container {
   min-width: 750px;
   height: 625px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   border-radius: 40px;
   border: 1px solid rgba(33, 230, 237, 0.50);
   background: radial-gradient(185.41% 85.93% at 34.59% 44.27%, #051F20 0%, #070508 47.5%);
}
.text {
   padding: 20px 40px;
}
.ubuntu {
   color: var(--primary-color);
   margin-left: 50px;
}
.flashing-character {
   font-size: 24px;
   animation: flash 1s infinite;
   font-family: 'Courier New', Courier, monospace;
   font-weight: 500;
}
@keyframes flash {
   0% { opacity: 0; }
   50% { opacity: 1; }
   100% { opacity: 0; }
}
.text h2 {
   font-family: 'Space Mono', 'Syne Mono', monospace;
}
.text-container p {
   font-size: 16px;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   line-height: 16px;
   margin: 15px 0 15px 0;
   text-indent: 10px;
}
.text-container p.special--text {
   font-family: 'Julee', 'Rancho', cursive;
   text-align: center;
   font-size: 18px;
   line-height: 22px;
}
.image{
   width: 275px;
   height: 275px;
   background: linear-gradient(0deg, #21E6ED -10.33%, rgba(27, 113, 169, 0.75) 47.55%, rgba(14, 29, 37, 0.10) 100%);
   border-radius: 50%;
   margin-top: 30px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.image img{
   border-radius: 50%;
   width: 250px; height: 250px;
   background: var(--bg-color);
}

@media only screen and (min-width: 768px) and (max-width: 1367px){
   .containers {
      width: 90%;
      margin: 0;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
   }
   .img-container {
      height: 550px;
      min-width: 300px;
   }
   .img-container::before {
      height: 550px;
      min-width: 300px;
   }
   .image {
      width: 250px;
      height: 250px;
   }
   .image img{
      width: 225px;
      height: 225px;
   }
   .short--descriptions {
      margin-left: 15px;
      margin-top: 50px;
      grid-template-columns: 75px 175px;
      gap: 5px 15px;
   }
   .short--descriptions--subject {
      font-size: 16px;
   }
   .short--descriptions--text {
      font-size: 14px;
   }
   .text-container {
      height: 550px;
      min-width: 550px;
   }
   .text-container p {
      margin: 5px 0 10px 0;
   }
   .text-container::before {
      height: 550px;
      min-width: 625px;
   }
   .text {
      padding: 25px;
   }
   .text h2 {
      font-size: 16px;
   }
   .text p {
      font-size: 12px;
   }
   .text p.special--text {
      font-size: 20px;
   }
}

@media (orientation: portrait) {
   .about {
      display: flex;
      flex-direction: column;
   }
   .containers {
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 900px;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
   }
   .img-container {
      flex-direction: row;
      gap: 30px;
      height: 300px;
      width: 635px;
      min-width: auto;
      flex: unset;
      justify-content: center;
      align-items: center;
   }
   .image img {
      width: 200px;
      height: 200px;
   }
   .image {
      margin: 0;
      width: 225px;
      height: 225px;
   }
   .img-container::before {
      content: '';
      position: absolute;
      width: 635px;
      height: 300px;
      background: var(--bg-color);
      z-index: -1;
   }
   .text-container {
      flex: initial;
      width: 635px;
      min-width: auto;
   }
   .text {
      overflow-y: scroll;  
   }
}
@media only screen and (min-height: 768) and (max-height: 1072), screen and (orientation: portrait) {
   .containers {
      margin: 50px 0 0 0;
   }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
   .about{ 
      /* height: unset; */
      align-items: flex-start;
      overflow-y: scroll;
   }
   .about > * {
      overflow: unset;
   }
   .containers {
      justify-content: center;
      position: initial;
      transform: none;
      height: unset;
   }
   .title {
      font-size: 32px;
      position: relative;
      
   }
   .img-container {
      flex-direction: column;
      min-width: 250px;
      width: 90%;
      max-width: 450px;
      min-height: 600px;
   }
   .img-container::before{
      width: 100%;
      height: 100%;
   }
   .text-container {
      min-width: 250px;
      width: 90%;
      max-width: 450px;
      height: fit-content;
      min-height: 550px;
   }
   .text-container::before {
      width: 90%;
      height: 122%;
   }
   .text {
      height: fit-content;
      font-size: 12px;
      text-align: center;
      padding: 10px;
   }
   .text p {
      font-size: 9px;
      text-align: start;
   }
   .text .ubuntu {
      margin: 0;
   }
}