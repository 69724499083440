.contact {
   position: absolute;
   height: 100dvh;
   width: 100dvw;
   z-index: 1;
   background-size: cover !important;
   background-color: var(--bg-color);
   display: flex;
   justify-content: center;
   align-items: center;

   opacity: 1;
   visibility: visible;
   transition: all 0.9s ease-in 1s;
}
.contact--invisible {
   opacity: 0;
   visibility: hidden;
   transition: all 0.9s ease-out;
}
.contact .title::before {
   content: 'Contact Me';
}
.contact .containers {
   margin-left: 15%;
}
.socials--container {
   height: 600px;
   min-width: 350px;
   border-radius: 40px;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   border: 1px solid rgba(33, 230, 237, 0.40);
   background: linear-gradient(128deg, #070508 -0.96%, #031616 46.81%, #070508 108.21%);

}
.form--container {
   height: 600px;
   width: 550px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 10px;
   border-radius: 40px;
   border: 1px solid rgba(33, 230, 237, 0.50);
   background: radial-gradient(109.53% 146.1% at 22.38% 52.44%, #031616 0%, #070508 72.16%);
}
.socials--title {
   font-size: 28px;
   margin: 30px auto;
   font-family: 'Ubuntu Mono', serif;
}
.socials--title > .ubuntu {
   margin: 0;
}
.social--link {
   font-family: 'Inter', sans-serif;
   margin-left: 60px;
   font-size: 30px;
   width: 100%;
   height: 50px;
}
.social--link:hover {
   color: rgba(33, 230, 237, 0.50);
}

/* ---------- Form styling ---------- */
.form {
   margin: 0;
   padding: 0;
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.form .ubuntu{
   margin: 0;
}
.form p {
   margin: 25px 0;
   font-family: 'Ubuntu Mono', serif;
   font-size: 28px;
}
form {
   width: 100%;
   height: 70%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;
}

input,
textarea {
   all: unset;
}
textarea {
   font-size: 18px !important;
}
.form-control--row {
   position: relative;
   border-bottom: 1px solid rgba(33, 230, 237, 0.40);
   width: 80%;
}
.form-control {
   font-size: 24px;
   font-family: 'Inter', sans-serif;
   padding: 25px 0 5px 0;
   border-radius: 5px;
   background-color: transparent;
   width: 100%;
   transition: opacity 1.5s ease, color 1.5s ease;
   text-indent: 20px;
}
.form-label {
   position: absolute;
   top: 20px;
   left: 10px;
   color: rgba(241,242,243,0.4);
   font-size: 24px;
   transition: all 0.3s ease;
   pointer-events: none;
   margin: 0;
}
.form-control:focus + .form-label,
.form-control:not(:placeholder-shown) + .form-label {
   top: 5px;
   left: 10px;
   font-size: 16px;
   color: rgba(33, 230, 237, 0.60);
}

.form-control.disabled {
   opacity: 0.5;
}

.form-control:focus {
   background-color: rgba(0, 0, 0, 0.1);
   outline: none !important;
   border-color: var(--primary-color);
   transition: all 0.5s ease;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
   -webkit-background-clip: text;
   -webkit-text-fill-color: var(--text-color);
   transition: background-color 5000s ease-in-out 0s;
   box-shadow: inset 0 0 35px 26px rgba(33, 230, 237, 0.20);
}
.submit--btn {
   margin-top: 25px;
   width: 30%;
   position: relative;
}
.sent-message {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   top: 50%; left: 50%;
   transform: translate(-50%, -50%);
   border-radius: 10px;
   z-index: 8;
   background: linear-gradient(128deg, #0B3030 -26.98%, #070508 48.27%, #051818 124.42%);
   /* border: 1px solid rgba(33, 230, 237, 0.40); */
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease-out;
}
.btn-loader {
   position: absolute;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   top: 50%; left: 50%;
   transform: translate(-50%, -50%);
   border-radius: 10px;
   z-index: 8;
   background: linear-gradient(128deg, #0B3030 -26.98%, #070508 48.27%, #051818 124.42%);
   opacity: 1;
   visibility: visible;
   transition: all 0.3s ease-in;
}

.spinner {
   position: relative;
   width: 25px; height: 25px;
   background: var(--gradient);
   border-radius: 50%;
   z-index: 10;
   animation: rotateCircle 1.5s linear infinite;

}
.spinner::before {
   content: '';
   position: absolute;
   left: 50%; top: 50%;
   transform: translate(-50%, -50%);
   width: 20px; height: 20px;
   z-index: 9;
   background: var(--bg-color);
   border-radius: 50%;

}
.sent-message {
   z-index: 9;

   svg {
      margin: 0 5px -2px 0;
      fill: var(--primary-color);
      transform: translateX(-100px);
      transition: all 0.25s linear;
   }
}
.sent-message.visible {
   opacity: 1;
   visibility: visible;
   transition: all 0.3s ease-in;
   svg {
      transition: all 0.25s linear;
      transition-delay: 1s;
      transform: translateX(0);
   }
}




@media only screen and (min-width: 768px) and (max-width: 1367px) {
   .contact .containers {
      margin: 0 0 0 60px;
      width: 80%;
   }
   .socials--container {
      height: 550px;
      min-width: 325px;
      position: relative;
   }
   .socials--title {
      font-size: 24px;
   }
   .socials--title .flashing-character {
      font-size: 32px;
   }
   .social--link {
      font-size: 22px;
      margin-left: 100px;
   }
   .form--container {
      height: 550px;
   }
   .form p {
      font-size: 24px;
   }
   .form-control--row {
      line-height: 16px;
   }
}

@media (orientation: portrait) {
   .contact .containers {
      margin: 0;
      width: 90%;
      height: 80%;
      flex-direction: column-reverse;
      top: 50%; left: 50%;
      transform: translate(-50%, -60%);
   }
   .form--container::before {
      width: 60%;
      height: 40%;
   }
   .socials--container {
      height: 225px;
      padding: 25px;
   }
   .socials--container::before {
      height: 225px;
      width: 750px;
      left: -25px
   }
   .social--links {
      display: flex;
   }
   .social--link:not(:last-child){
      border-right: 1px solid rgba(255,255,255, 0.40);
   }
   .social--link {
      margin: 0;
      width: fit-content;
      padding: 0 10px;
      display: flex;
      align-items: center;
   }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
   .contact > * {
      overflow: unset;
   }
   .contact {
      height: 100dvh;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
   }
   .contact .title {
      font-size: 26px;
      top: 25px;
   }

   .contact .containers {
      justify-content: center;
      position: initial;
      height: unset;
      transform: none;
      margin-top: 20%;
   }
   .socials--container {
      min-width: 250px;
      width: 90%;
      max-width: 450px;
      min-height: 450px;
   }
   .social--links{
      flex-direction: column;
   }
   .social--link:not(:last-child){
      border: none;
   }
   .form--container {
      height: unset;
      width: 90%;
   }
   .form p{
      font-size: 20px;
      text-align: center;
   }
   .form .btn {
      font-size: 16px;
      margin: 10px 0 15px 0;
   }
   .social--link {
      font-size: 22px;
      margin-left: 40px;
   }
   .socials--title {
      text-align: center;
      font-size: 22px;
   }
   .form--container {
      min-height: 500px;
   }

}