.policy--navbar {
   width: 100%;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.policies--navbar {
   width: 90%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.home--link {
   font-size: 32px;
   font-family: 'Lemon', serif;
}
.back--home {
   padding-bottom: 5px;
}
.back--home:hover  {
   border-bottom: 3px solid var(--primary-color);
   transition: all 0.1s ease-in;
}
@media screen and (min-width: 1440px ) {
   .policies--navbar {
      width: 60%;
   }
}
