.cv-layout {
   position: inherit;
   height: 200px;
   padding-top: 20px;
   margin-bottom: 25px;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.exp-edu {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   gap: 30px;
   overflow-x: hidden;
}

.experience, .education {
   flex: 0 0 40%;
   padding-bottom: 30px;
}

.resume-container h1.position {
   padding-left: 5px;
   margin: 0;
}
.resume-container p.date {
   padding: 0 0 0 5px;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: var(--primary-color);
   font-size: 16px;
}
.resume-container {
   border-left: 2px solid var(--primary-color);
   margin-left: 20px;
   padding: 5px 0 0 20px;
   position: relative;
   /* height: 100% */
}
.border--container {
   position: relative;
}
.border--container::before {
   content: '';
   width: 25px;
   height: 25px;
   background-color: var(--bg-color);
   border: 2px solid var(--primary-color);
   border-radius: 50%;
   position: absolute;
   left: 9px;
   top: 0;
   z-index: 1;
}

.resume-container:not(:last-child){
   padding-bottom: 20px !important;
}
p.date {
   text-transform: uppercase;
   font-size: 14px;
   font-weight: 600 !important;
   white-space: nowrap;
   margin: 0;
   margin-top: 5px;
   border-radius: 10px;
   color: var(--text-color);
}

.accent {
   color: var(--text-color) !important;
}

p.modules {
   font-family: 'Space Mono', 'Syne Mono', monospace;
   margin: 10px 0 0 0; padding-left: 5px;
   font-weight: bold;
   font-size: 18px;
}

p.more-info {
   text-align: center;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: var(--text-color);
   font-size: 12px;
}
.resume-container .job-container p.more-info a{
   color: var(--primary-color);
   text-decoration: none;
}
.resume-container .job-container p.more-info a:hover {
   color: var(--light-mode-accent-color);
}
.resume-container .job-container p.more-info a:active {
   font-size: 11.5px;
   transition: 0.3s ease;
}
span.font-edit{
   margin: 0;
   color: var(--primary-color);
}
span.font-edit svg {
   fill: var(--primary-color);
}
.resume-container .job-container .font-edit.cert-text .fas.fa-image {
   margin: 0; padding: 0;
   display: unset;
}
.resume-container .job-container .font-edit.cert-text {
   margin-top: 15px;
   margin-bottom: 10px;
   cursor: alias;
}
.resume-container .job-container .font-edit.cert-text:hover {
   font-size: 19px;
   transition: 0.3s ease;
   color: rgba(33, 230, 237, 0.70);
}
.resume-container .job-container .font-edit.cert-text:hover svg {
   fill: rgba(33, 230, 237, 0.70);
}
.resume-container h1.company {
   margin: 0;
   transform: translateY(-5px);
   font-family: 'Ubuntu Mono', serif;
   font-size: 24px;
}
.resume-container h1.company a{
   text-decoration: none;
   color: var(--text-color);
}
.resume-container h1.company svg {
   stroke: var(--text-color);
   margin: -3px -6px;
}
.resume-container h1.company a:hover {
   color: var(--primary-color);
}
.resume-container h1.company a:hover svg {
   stroke: var(--primary-color);
}
.resume-container h1.company a:active {
   font-size: 19px;
   transition: 0.3s ease;
}

.resume-container .job-container {
   text-indent: 15px;
}
.resume-container .job-container h1.position {
   font-size: 20px;
   font-family: 'Ubuntu Mono', serif;
   padding-top: 10px;
}
.resume-container .job-container h1.position a{
   text-decoration: none;
   color: var(--primary-color);
   font-size: 18px;
}
.resume-container .job-container h1.position svg {
   stroke: var(--primary-color);
   margin: -2px -4px;
}
.resume-container .job-container h1.position a:hover {
   color: rgba(33, 230, 237, 0.70)
}
.resume-container .job-container h1.position a:hover svg {
   stroke:rgba(33, 230, 237, 0.70)
}
.resume-container .job-container h1.position a:active {
   font-size: 13px !important;
   transition: 0.3s ease;
}
.resume-container .job-container .fas.fa-external-link-alt {
   display: unset;
}
.resume-container .job-container ul li{
   list-style-type: circle;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: rgba(249, 250,250, 0.8)
}
.resume-container .job-container ul {
   margin: 0;
   padding: 15px 0;
}
.resume-container.no-company::before {
   border: none;
   background-color: transparent;
}

.exp-edu-h-container {
   text-align: center;
   font-family: 'Space Mono', 'Syne Mono', monospace;
   color: rgba(249, 250,250, 0.8);
   font-size: 22px;
   margin: 0; padding: 5px 0 20px 0;
   color: var(--primary-color)
}
.exp-edu-h-container .exp-edu-h {
   margin: 0;
}

p.font-edit {
   font-size: 18px;
   margin: 0;
   color: var(--primary-color);
}

.certs-heading {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0; padding: 0;
}

.certs-heading h2 {
   font-family: 'Ubuntu Mono', serif;
   margin: 0; padding: 0;
   margin-top: 20px;
}

.cert-proj {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
}
.cert-proj-flex-anim {
   display:flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
}

#About, .certs-proj-bg {
   background: radial-gradient(circle at top left,transparent 9%, #14141c 10% ,#14141c 15% , transparent 16%) ,
   radial-gradient(circle at bottom left,transparent 9%, #14141c 10% ,#14141c 15% , transparent 16%),
   radial-gradient(circle at top right ,transparent 9%, #14141c 10% ,#14141c 15% , transparent 16%) ,
   radial-gradient(circle at bottom right,transparent 9%, #14141c 10% ,#14141c 15% , transparent 16%),
   radial-gradient(circle, transparent 25%, #0F0F17  26%),
   linear-gradient(45deg, transparent 46%, #14141c 47%, #14141c 52%, transparent 53%),
   linear-gradient(135deg, transparent 46%, #14141c 47%, #14141c 52%, transparent 53%);
   background-size: 2em 2em;
   background-color: #0F0F17;
   opacity: 1
}

.item-container {
   position: relative;
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
   margin-bottom: 20px;
}

.item-card {
   position: relative;
}

.item-card .face {
   width: 300px;
   height: 200px;
   transition: 0.5s ease;
}
.item-card .face.face1 p.tap{
   display:none;
}

.item-card .face.face1 {
   position: relative;
   background: var(--bg-color);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1;
   transform: translateY(100px);
}

.item-card:hover .face.face1 {
   background: var(--primary-color);
   transform: translateY(0);
}

.item-card .face.face1 .content {
   opacity: 0.4;
   transition: 0.5s ease;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.item-card:hover .face.face1 .content {
   opacity: 1;
}

.item-card .face.face1 .content img {
   max-width: 100px;
}

.item-card .face.face1 .content h3{
   margin: 10px 0 0;
   padding: 0;
   color: var(--text-color);
   text-align: center;
   font-family: 'Ubuntu Mono', serif;
}

.item-card .face.face2 {
   position: relative;
   background: var(--light-mode-background);
   display: flex;
   justify-content: center;
   box-sizing: border-box;
   transform: translateY(-100px);
   box-shadow: 0 20px 50px rgba(0, 0, 0, 0.4);
}

.item-card:hover .face.face2{
   transform: translateY(0);
}

.item-card .face.face2 .content {
   display: flex; 
   flex-direction: column;
   justify-content: center;
}

.item-card .face.face2 .content p {
   margin: 0;
   padding: 0;
}

.item-card .face.face2 .content .date.item-date {
   text-align: center;
   margin: 2px;
   color: var(--light-mode-color);
}

.item-card .face.face2 .content .font-edit {
   color: var(--primary-color);
   text-align: center;
}

.item-card .face.face2 .content .item-text {
   color: var(--light-mode-text-color);
   margin: 5px;
   text-align: center;
}
.fadeout-container {
   position: relative;
   display: inline-block;
}
.fadeout-text {
   position: relative;
   z-index: 1;
}
.fadeout-gradient {
   position: absolute;
   right: 0;
   top: 0;
   bottom: 0;
   width: 150px; /* Adjust the width of the fade effect */
   background: linear-gradient(to right, rgba(241, 242, 243, 0.1), var(--light-mode-background));
   z-index: 2;
}


.item-card .face.face2 .content .font-edit.cert-text {
   margin-top: 5px;
   margin-bottom: 10px;
   cursor: alias;
}

.item-card .face.face2 .content .font-edit.cert-text:hover {
   font-size: 20px;
   transition: 0.3s ease;
}

.read-more{
   color: var(--light-mode-text-color);
   font-family: 'Ubuntu Mono', serif;
   font-size: 12px;
   cursor: alias;
}

.read-more:hover {
   color: var(--primary-color);
   font-size: 13px;
   transition: 0.3s ease;
}


@media screen and (max-width: 640px) {
   .exp-edu {
      flex-direction: column;
   }
   .exp-edu-h {
      font-size: 20px;
   }
   .experience {
      font-size: 10px;
   }
   p.more-info {
      font-size: 10px;
   }
   .resume-container .job-container h1.position {
      font-size: 16px;
   }
   .resume-container p.date {
      font-size: 13px;
   }
}
@media screen and (min-width: 640px) and (max-width: 1140px) {
   .experience, .education {
      flex: 0 0 46%;
   }
}