.dataForm--container {
    height: 100dvh;
    width: 100dvw;
    background: linear-gradient(to right bottom, #070508, #0a090f, #0a0e14, #081218,  #081218, #0a0e14, #0a090f, #070508);
    display: flex;
    justify-content: center;
    align-items: center;
}
.main--container {
    width: 80%;
    height: 80%;
    background: rgba(33, 230, 237, 0.02);
    border: 1px solid rgba(33, 230, 237, 0.4);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2.5rem 3.25rem;
}
.f-title {
    text-align: center;
    font-family: 'Ubuntu Mono', monospace;
    font-size: 32px;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}
.f-subtitle {
    text-align: center;
    font-family: 'Space Mono', 'Syne Mono', monospace;
    font-size: 24px;
    color: rgba(249, 250, 254, 0.8);
}
.f-subtitle svg {
    margin-bottom: -9px;
    font-size: 36px;
    fill: #21e6ed;
}
.f-text {
    /* text-align: center; */
    font-family: 'Space Mono', 'Syne Mono', monospace;
    font-size: 16px;
    color: rgba(249, 250, 254, 0.7);
    margin-top: 2.5%;
}
.f-text .bold {
    font-weight: bold;
}
.f-text.sm-text {
    font-size: 14px;
    text-align: center;
}
.f-b-text {
    text-align: center;
    font-family: 'Space Mono', 'Syne Mono', monospace;
    font-size: 16px;
    color: rgba(249, 250, 254, 0.7);
}
.f-b-text.first-child {
    color: rgba(249, 250, 254, 1);
    font-weight: 600;
}
.f-list {
    text-align: center;
    font-family: 'Space Mono', 'Syne Mono', monospace;
    font-size: 16px;
    color: rgba(249, 250, 254, 0.7);
    margin-top: 2.5%;
}
.f-list-item {
    color: rgba(249, 250, 254, 0.8);
}
.f-list-item svg {
    font-size: 18px;
    margin-bottom: -4px;
    margin-right: 5px;
    stroke: #21e6ed;
}
.f-list-item a:hover {
    color: #21e6ed;
    cursor: pointer;
}
.f-list-item a {
    color: rgba(249, 250, 254, 1);
    text-decoration: none;
    font-family: 'Space Mono', 'Syne Mono', monospace;
}
.button {
    font-family: 'Space Mono', 'Syne Mono', monospace;
    font-size: 16px;
    font-weight: 500;
    color: rgba(249, 250, 254, 1);
    background-color: rgba(33, 230, 237, 0.4);
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    margin-top: 2.5%;
    cursor: pointer;
    transition: 0.3s;
}
.button:hover {
    background-color: rgba(33, 230, 237, 0.6);
}
.button:active{
    background-color: rgba(33, 230, 237, 0.8);
    transform: scale(0.95);
}
.button:focus {
    outline: none;
    background-color: rgba(33, 230, 237, 0.6);
}
.f-b-text a:hover{
    color: #21e6ed;
    cursor: pointer;
}
a:focus {
    color: #21e6ed;
    cursor: pointer;
}